import AOS from 'aos';

const pressRelease = (()=>{
    const sectionName = 'press-release';

    const setupShowMoreBtn = (section) => {
        const btn = section.querySelector(`.${sectionName}__more-btn`);
        if (!btn) return;

        const listItems = section.querySelectorAll(`.${sectionName}-list__item`);
        
        if (listItems.length <= 10) {
            section.querySelector(".press-release__more-btn-row").classList.add("d-none");
            return;
        }

        listItems.forEach((item, index) => {
            if (index < 10) return;
            item.classList.add(`${sectionName}-list__item--hide`);
        });

        section.querySelectorAll(`.press-release__list:not(:has(.press-release-list__item:not(.press-release-list__item--hide)))`).forEach((list) => {
            list.classList.add(`press-release__list--hide`);
        });

        AOS.init();

        btn.addEventListener("click", (e) => {
            e.preventDefault();

            const hiddenItems = section.querySelectorAll(`.${sectionName}-list__item--hide`);
            
            hiddenItems.forEach((item, index) => {
                if (index >= 10) return;
                item.classList.remove(`${sectionName}-list__item--hide`);

                if (hiddenItems.length <= 10) {
                    btn.classList.add(`${sectionName}__more-btn--hide`);
                }
            });

            section.querySelectorAll(`.press-release__list`).forEach((list) => {
                list.classList.remove(`press-release__list--hide`);
            })

            section.querySelectorAll(`.press-release__list:not(:has(.press-release-list__item:not(.press-release-list__item--hide)))`).forEach((list) => {
                list.classList.add(`press-release__list--hide`);
            });

            AOS.init();

            hiddenItems[0].querySelector("a, btn").focus(); 
            
        })
    }

    const setupResetBtn = (section) => {
        const resetBtns = section.querySelectorAll(".listing-searchbar__reset-btn");
        const bsSelects = section.querySelectorAll(".js-bs-select");

        if (!resetBtns || !bsSelects) return;

        resetBtns.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                $(bsSelects).val("");
                $(bsSelects).selectpicker("refresh");
            });
        })
    }
    
    const setup = () => {

        document.querySelectorAll(`.${sectionName}`).forEach((section) => {
            setupShowMoreBtn(section);
            setupResetBtn(section);
        })
    }

    return {init: setup}
})();

export default pressRelease;

// $(".press-release-list:has(.press-release-list__item:not(.press-release-list__item--hide))")
// $(".press-release-list:not(:has(.press-release-list__item:not(.press-release-list__item--hide)))")
